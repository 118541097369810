import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import CarouselLayout from "../components/carousel-layout";

export const query = graphql`
  query ColophonPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      _rawColophonPageContent(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const ColophonPage = ({ data }) => {
  return (
    <>
      <SEO title="Colophon" />
      <CarouselLayout
        title={<h1>Colophon</h1>}
        blocks={data?.site?._rawColophonPageContent}
      />
    </>
  );
};

export default ColophonPage;
